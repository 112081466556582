import React from "react";

export default ({ children }) => (
  <>
    <div className="border border-verde-agroalpha w-8 mb-2" />
    <h4 className="font-body uppercase pb-4 text-verde tracking-wide">
      {children}
    </h4>
  </>
);
