import React from "react";
import Section from "./section";
import SubTitle from "./sub-title";

function Contact() {
  return (
    <Section title="Contato">
      <div className="flex -m-2 flex-wrap md:flex-nowrap">
        <div className="w-full md:w-1/2 p-0 md:pl-2">
          <SubTitle>Formulário de Contato</SubTitle>
          <form
            name="contact"
            method="post"
            action="/mensagem-enviada"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <div className="pb-4">
              <label htmlFor="nome">Nome</label>
              <div className="control">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="nome"
                  id="nome"
                  type="text"
                  autoComplete="name"
                />
              </div>
            </div>
            <div className="pb-4">
              <label htmlFor="email">E-mail</label>
              <div className="control">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  id="email"
                  type="email"
                  autoComplete="email"
                />
              </div>
            </div>
            <div className="pb-4">
              <label htmlFor="assunto">Assunto</label>
              <div className="control">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="assunto"
                  id="assunto"
                  type="text"
                />
              </div>
            </div>
            <div className="pb-4">
              <label htmlFor="mensagem">Mensagem</label>
              <div className="control">
                <textarea
                  className="shadow appearance-none border rounded h-64 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="mensagem"
                  id="mensagem"
                />
              </div>
            </div>
            <div data-netlify-recaptcha />
            <div>
              <button
                className="bg-green-700 hover:bg-green-800 text-white text-center uppercase px-4 py-1 block rounded"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
        <div className="w-full md:w-1/2 p-0 md:pl-16">
          <SubTitle>Endereço</SubTitle>
          <p className="mb-8">
            Rua Doutor Timóteo, 727, sala 601
            <br />
            90570-040 – Porto Alegre, RS – Brasil
          </p>
          <SubTitle>Telefone</SubTitle>
          <p className="mb-8">
            <a href="tel:555130190305">+55 51 3019 0305</a>
          </p>
          <SubTitle>E-mail</SubTitle>
          <p>
            <a href="mailto:agroalpha@agroalpha.agr.br">
              agroalpha@agroalpha.agr.br
            </a>
          </p>
        </div>
      </div>
    </Section>
  );
}

export default Contact;
