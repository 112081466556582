import React from "react";
import Button from "./button";
import image from "../images/image32.png";

export default () => (
  <section
    className="bg-local bg-cover bg-center"
    style={{
      backgroundImage: `
          linear-gradient(rgba(2,62,64,0.85),rgba(2,62,64,0.8)),
          url(${image})
        `
    }}
  >
    <div className="flex flex-col justify-center items-center container h-full py-16">
      <h3 className="text-white mb-4">
        Quer saber onde encontrar nossas sementes?
      </h3>
      <Button to="/#sementes">Encontre um produtor licenciado</Button>
      <h3 className="text-white mt-12 mb-4">
        Quer produzir sementes de cultivares Agroalpha?
      </h3>
      <Button to="/#licenciamento" variant="secondary">
        Licencie aqui
      </Button>
    </div>
  </section>
);
