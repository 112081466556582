import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Title from "./title";
import Button from "./button";
import CultivarDetail from "./cultivar-details";
import cultivarIcon from "../images/agroalpha-icon.png";

function CultivarList() {
  const data = useStaticQuery(graphql`
    query CultivaresQuery {
      allCultivarYaml(
        filter: { ativa: { eq: true } }
        sort: { fields: [nome, cultura], order: ASC }
      ) {
        edges {
          node {
            key
            nome
            cultura
            secoes {
              nome
              entradas {
                chave
                valor
              }
            }
          }
        }
      }
    }
  `);
  const { edges: cultivares } = data.allCultivarYaml;

  const [detail, setDetail] = useState("");

  return (
    <>
      <Title variant="h3">Nossas cultivares</Title>
      <div className="flex flex-wrap -mx-2 justify-center">
        {cultivares.map(({ node: cultivar }) => (
          <div key={cultivar.key} className="flex h-48 w-1/2 md:w-1/3 p-2">
            <div className="flex flex-auto flex-col justify-between items-center rounded-lg shadow p-4">
              <div className="flex items-center">
                <img src={cultivarIcon} />
                <Title variant="h5">{cultivar.nome}</Title>
              </div>
              <div className="flex">{cultivar.cultura}</div>
              <div>
                <Button
                  useA
                  onClick={cultivar.secoes && (() => setDetail(cultivar.key))}
                >
                  Ver detalhes
                </Button>
              </div>
              {detail === cultivar.key && (
                <CultivarDetail
                  data={cultivar}
                  title={cultivar.nome}
                  onClose={() => setDetail("")}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default CultivarList;
