import React from "react";

const variantToClassName = {
  h2: "py-4 md:py-8",
  h3: "py-4 md:py-8"
};

export default ({ children, variant = "h1" }) => {
  const Element = variant;
  if (typeof children === 'string') {
    return (
      <Element className={variantToClassName[variant]} dangerouslySetInnerHTML={{ __html: children }} />
    );
  } else {
    return (
      <Element className={variantToClassName[variant]}>{children}</Element>
    );
  }
  
};
