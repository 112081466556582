import React from "react";

import Header from "../components/header";
import Hero from "../components/hero";
import Cultivars from "../components/cultivars";
import Lead from "../components/lead";
import Licensing from "../components/licensing";
import WhoWeAre from "../components/who-we-are";
import Contact from "../components/contact";
import Footer from "../components/footer";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <>
      <SEO
        keywords={[`agroalpha`, `cultivares`, `aveia`, `aveia preta`, `agro esteio`, `agro quarai`, `af 1340`, `agro bage`, `agro planalto`]}
        title="Agroalpha"
      />
      <Header />
      <Hero />
      <Cultivars />
      <Lead />
      <Licensing />
      <WhoWeAre />
      <Contact />
      <Footer />
    </>
  );
}

export default IndexPage;
