import React from "react";
import Section from "./section";
import grainImg from "../images/image1198.png";
import fieldImg from "../images/image4616.png";

export default () => (
  <Section title="Licenciamento">
    <div className="flex">
      <div className="w-full md:w-1/2">
        <p className="mb-4">
          A Agroalpha é pioneira no desenvolvimento de cultivares de aveia preta
          no Brasil, e também desenvolve cultivares de aveia branca, amarela e
          cevada. Em cada uma dessas variedades, primamos pela qualidade
          genética, atendendo às necessidades individuais de cada agricultor e
          seus negócios, garantindo a legitimidade e tranquilidade dos
          compradores.
        </p>
        <p className="mb-4">
          O licenciamento de cultivares é um processo pelo qual os produtores de
          sementes obtêm autorização para produzir e comercializar cultivares de
          propriedade intelectual dos obtentores, mediante o pagamento de
          royalties.
        </p>
        <p>
          Em parceria com a{" "}
          <a
            href="https://www.netseeds.com.br"
            rel="noopener noreferrer"
            title="NetSeeds"
            target="_blank"
          >
            NetSeeds
          </a>
          , oferecemos um processo de licenciamento transparente e eficiente.
        </p>
      </div>
      <div className="w-1/2 hidden md:block">
        <img
          src={grainImg}
          className="rounded-sm shadow-lg ml-64 -mt-24 hidden md:block"
        />
        <img
          src={fieldImg}
          className="rounded-sm shadow-lg ml-8 -mt-64 hidden md:block"
        />
      </div>
    </div>
    <div>
      <h3 className="pt-8 pb-2">Processo de Licenciamento</h3>
    </div>
    <div>
      <p className="mb-4">
        Para mais informações sobre o processo de licenciamento, consulte os
        passos abaixo:
      </p>
    </div>
    <div className="flex flex-wrap -mx-2">
      {[
        "Pré aprovação pelo Agroalpha para produção e comercialização",
        <>
          Solicitação de Licenciamento, encaminhada eletronicamente através do
          portal{" "}
          <a
            href="https://www.netseeds.com.br"
            rel="noopener noreferrer"
            title="NetSeeds"
            target="_blank"
          >
            NetSeeds
          </a>
        </>,
        "Aquisição de Sementes de categoria superior e comprovação da origem",
        "Assinatura de contrato de licenciamento para produção e comercialização",
        "Emissão da autorização pelo Agroalpha",
        "Inscrição dos campos de produção junto ao MAPA",
        <>
          Encaminhamento de relatórios através do portal{" "}
          <a
            href="https://www.netseeds.com.br"
            rel="noopener noreferrer"
            title="NetSeeds"
            target="_blank"
          >
            NetSeeds
          </a>
        </>,
        "Pagamento de Royalties e Contribuições",
      ].map((item, index) => (
        <div key={index} className="flex w-1/2 p-2">
          <div className="flex flex-auto py-4">
            <div className="font-header text-green-600 text-3xl border-t border-verde-agroalpha px-2">
              {index + 1}
            </div>
            <div className="pl-4 -mt-1">{item}</div>
          </div>
        </div>
      ))}
    </div>
    <div>
      <p>
        Este processo tem por objetivo fornecimento de Sementes Legais, com
        identidade e pureza genética, produzidas para atender também qualidades
        físicas e fisiológicas das sementes.
      </p>
    </div>
  </Section>
);
