import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Button from "./button";
import image from "../images/image118.png";

function Hero() {
  return (
    <section
      className="bg-local bg-cover bg-center h-screen"
      style={{
        backgroundImage: `
          linear-gradient(rgba(2,62,64,0.8),rgba(2,62,64,0.5)),
          url(${image})
        `
      }}
    >
      <div className="flex flex-col justify-center items-center container h-full">
        <h1 className="text-center text-white leading-tight">
          Genética em Aveias e
          <br />
          Cevadas Forrageiras e Graníferas
        </h1>
        <div className="text-lg text-center text-white pt-4">
          Alto investimento em pesquisa e inovação focada na busca de cultivares
          <br />
          adaptadas aos diversos sistema de produção agrícola e pecuária
        </div>
        <div className="flex flex-row pt-8">
          <div className="m-2">
            <Button to="/#cultivares">Nossas cultivares</Button>
          </div>
          <div className="m-2">
            <Button to="/#contato" variant="secondary">
              Fale com a gente
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
