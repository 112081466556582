import React from "react";
import Section from "./section";
import SubTitle from "./sub-title";
import imageCows from "../images/image4740.png";
import imageField from "../images/image4812.png";

export default () => (
  <Section title="Quem somos">
    <div className="flex flex-wrap md:flex-nowrap pb-8">
      <div className="w-full md:w-1/2 pr-8">
        <p className="mb-4">
          A Agroalpha foi fundada em <strong>1988</strong> com a missão de
          oferecer serviços técnicos especializados para o agronegócio. Desde o
          início, nossa estratégia foi focada no desenvolvimento de produtos que
          nos proporcionassem receitas provenientes de direitos de uso.
          Observando as tendências globais, especialmente os efeitos da
          globalização, era evidente que a proteção dos direitos sobre as
          obtenções na área da genética vegetal se tornaria relevante no Brasil.
          Em 25 de abril de 1997, foi promulgada a Lei 9456, conhecida como Lei
          de Proteção de Cultivares, validando nossa visão estratégica.
        </p>
        <p>
          Foi com essa perspectiva que em <strong>1992</strong> iniciamos nosso
          programa de melhoramento de <strong>aveia preta</strong>. A decisão de
          investir no melhoramento genético dessa cultura foi impulsionada pelo
          avanço do Sistema de Plantio Direto e pelas demandas da pecuária,
          especialmente a leiteira. As características naturais da aveia preta,
          como sua rusticidade e capacidade de controle de nematoides e
          invasoras, também justificaram nossa escolha. Em 2003, registramos e
          protegemos a primeira cultivar de aveia preta no Brasil, que recebeu o
          nome de <strong>Agro Zebu</strong>.
        </p>
      </div>
      <div className="w-full md:w-1/2">
        <img
          src={imageCows}
          className="rounded-sm shadow-lg ml-8 -mt-16 hidden md:block"
        />
      </div>
    </div>
    <div className="flex flex-wrap md:flex-nowrap pb-0 md:pb-8">
      <div className="w-full md:w-1/2">
        <img
          src={imageField}
          className="rounded-sm shadow-lg mt-12 -ml-12 hidden md:block"
        />
      </div>
      <div className="w-full md:w-1/2">
        <p className="mb-4">
          Em <strong>2012</strong>, expandimos nosso programa de melhoramento
          para incluir a <strong>aveia amarela</strong>. Em{" "}
          <strong>2018</strong>, registramos e protegemos duas novas cultivares.
          A produção de sementes certificadas dessas cultivares teve início na
          safra de <strong>2019</strong>.
        </p>
        <p className="mb-4">
          Em <strong>2016</strong>, começamos a explorar cultivares de{" "}
          <strong>cevada</strong>, com foco na produção de forragem, cobertura
          do solo e oferta de grãos para alimentação animal. Comprometidos em
          ampliar nosso portfólio de soluções para os sistemas produtivos
          agrícolas, integrando lavoura e pecuária, lançamos nossa primeira
          cultivar de cevada na safra de <strong>2023</strong>.
        </p>
        <p>
          Paralelamente, desde <strong>2017</strong>, nossa parceria estratégica
          com a UFRGS tem proporcionado avanços significativos na obtenção de
          cultivares de <strong>aveia branca</strong>. Estamos orgulhosos em
          afirmar que novas cultivares foram integradas ao nosso portfólio já na
          safra de <strong>2021</strong>, fortalecendo nossa posição no mercado
          agrícola e consolidando nosso compromisso com a inovação e a
          qualidade.
        </p>
      </div>
    </div>
    <div className="flex flex-wrap md:flex-nowrap pb-0 md:pb-8">
      <div className="w-full md:w-1/2 pt-4 md:pt-0">
        <SubTitle>missão</SubTitle>
        <div>
          <p>
            Realizar melhoramento genético de Aveia Preta, Aveia Branca, Aveia
            Amarela e Cevada Forrageiras na busca de novas cultivares criando
            valor para sistemas de produção agropecuária.
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 pt-4 md:pt-0">
        <SubTitle>visão</SubTitle>
        <div>
          <p>
            Ser referência em melhoramento genético de Aveia Preta, Aveia
            Branca, Aveia Amarela e Cevada Forrageiras, reconhecida pela
            qualidade e inovação de suas cultivares.
          </p>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap md:flex-nowrap pb-0 md:pb-8">
      <div className="w-full md:w-1/2 pt-4 md:pt-0">
        <SubTitle>objetivos</SubTitle>
        <ul className="list-disc pl-4">
          <li>Fazer melhoramento genético focado nos sistemas produtivos</li>
          <li>
            Promover o modelo ganha-ganha com seus clientes e colaboradores
          </li>
          <li>
            Ser um dos líderes no mercado de cultivares de Aveia Preta, Aveia
            Branca, Aveia Amarela e Cevada Forrageiras
          </li>
        </ul>
      </div>
      <div className="w-full md:w-1/2 pt-4 md:pt-0">
        <SubTitle>valores</SubTitle>
        <div className="flex flex-auto">
          <ul className="w-1/2 list-disc px-4">
            <li>Parceria</li>
            <li>Liderança</li>
            <li>Compromisso com o cliente</li>
          </ul>
          <ul className="w-1/2 list-disc pl-4">
            <li>Ética</li>
            <li>Inovação</li>
            <li>Qualidade</li>
          </ul>
        </div>
      </div>
    </div>
  </Section>
);
