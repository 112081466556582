import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faList, faPhone } from "@fortawesome/free-solid-svg-icons";
import Title from "./title";

function Filter(props) {
  const color = (value) =>
    props.selectedValues.includes(value) ? "green" : "white";
  return (
    <div className="grid grid-cols-8 gap-2">
      <div className="block py-1 my-2">{props.label}</div>
      <div className="flex flex-row gap-4 my-2 flex-wrap col-span-7">
        {props.values.map((value) => (
          <button
            key={value}
            className={`
            border-${color(value)}-700 
            hover:border-${color(value)}-800 
            text-${color(value)}-700
            text-center
            px-4
            py-1
            block
            border-2
            rounded
        `}
            onClick={() => props.onItemClick(value)}
          >
            {value}
          </button>
        ))}
      </div>
    </div>
  );
}

export default () => {
  const [filter, setFilter] = React.useState({
    state: [],
    cultivar: [],
    crop: [],
  });
  const data = useStaticQuery(graphql`
    query SementesPage {
      allLicenciamentoYaml(
        sort: { order: ASC, fields: [produtor___nome] }
        filter: { safra: { eq: "2023/2023" } }
      ) {
        edges {
          node {
            categoria
            cultivar {
              key
              nome
              cultura
            }
            safra
            produtor {
              key
              nome
              cidade
              estado
              telefone
              contato
            }
          }
        }
      }
    }
  `);
  const licenciamentos = data.allLicenciamentoYaml.edges.map(
    ({ node }) => node
  );

  const estados = [
    ...new Set(licenciamentos.map((l) => l.produtor.estado)),
  ].sort();
  const cultivars = [
    ...new Set(licenciamentos.map((l) => l.cultivar.nome)),
  ].sort();
  const culturas = [
    ...new Set(licenciamentos.map((l) => l.cultivar.cultura)),
  ].sort();

  const produtores = Object.values(
    licenciamentos.reduce((acc, { produtor, ...licenciamento }) => {
      const x = acc[produtor.key];
      if (x) {
        if (x.licenciamentos) {
          x.licenciamentos.push(licenciamento);
        } else {
          x.licenciamentos = [licenciamento];
        }
      } else {
        produtor.licenciamentos = [licenciamento];
        acc[produtor.key] = produtor;
      }
      return acc;
    }, {})
  ).filter(
    (p) =>
      (filter.state.length === 0 || filter.state.includes(p.estado)) &&
      (filter.cultivar.length === 0 ||
        p.licenciamentos.some((l) => filter.cultivar.includes(l.cultivar.nome)))
  );

  function toggleFilter(filterName, filterValue) {
    setFilter((prevFilter) => {
      if (prevFilter[filterName].includes(filterValue)) {
        return {
          ...prevFilter,
          [filterName]: prevFilter[filterName].filter((x) => x !== filterValue),
        };
      } else {
        return {
          ...prevFilter,
          [filterName]: [...prevFilter[filterName], filterValue],
        };
      }
    });
  }
  return (
    <>
      <Title variant="h3">Produtores licenciados</Title>
      <div className="mb-4">
        <p className="pb-4">
          As sementes das cultivares de aveia preta, aveia branca, aveia amarela
          e cevada da Agroalpha já estão disponíveis no mercado. Se você está
          interessado na compra dessas sementes, é necessário contatar
          diretamente os produtores licenciados.
        </p>
      </div>
      <Filter
        label="Estado"
        values={estados}
        selectedValues={filter.state}
        onItemClick={(v) => toggleFilter("state", v)}
      />
      {/* <Filter
        label="Cultura"
        values={culturas}
        selectedValues={filter.crop}
        onItemClick={(v) => toggleFilter("crop", v)}
      /> */}
      <Filter
        label="Cultivar"
        values={cultivars}
        selectedValues={filter.cultivar}
        onItemClick={(v) => toggleFilter("cultivar", v)}
      />
      <div className="flex flex-wrap -mx-2">
        {produtores.length === 0 && (
          <div className="flex my-4">Nenhum produtor encontrado</div>
        )}
        {produtores.length > 0 &&
          produtores.map((p) => (
            <div key={p.nome} className="flex h-auto w-full md:w-1/3 p-2">
              <div className="flex flex-auto flex-col items-stretch rounded-lg shadow p-4">
                <div className="h-20 font-bold">{p.nome}</div>
                <div className="flex pb-2">
                  <div className="mr-4">
                    <FontAwesomeIcon icon={faCompass} />
                  </div>
                  <div>
                    {p.cidade}, {p.estado}
                  </div>
                </div>
                {p.telefone && (
                  <div className="flex pb-2">
                    <div className="mr-4">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div>{p.telefone}</div>
                  </div>
                )}
                <ul>
                  {p.licenciamentos
                    .sort((a, b) => {
                      return a.cultivar.nome.localeCompare(b.cultivar.nome);
                    })
                    .map(({ cultivar, categoria }) => (
                      <li
                        key={`${cultivar.nome}-${categoria}`}
                        className="flex"
                      >
                        <div className="mr-4">
                          <FontAwesomeIcon icon={faList} />
                        </div>
                        <div>
                          {cultivar.nome} ({categoria})
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
