import React from "react";
import Title from "./title";
import { graphql, useStaticQuery, Link } from "gatsby";

const toKebabCase = string =>
  string &&
  string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-");

function Section({ children, title }) {
  return (
    <section className="container max-w-4xl mx-auto p-4 md:p-8">
      <Title variant="h2">
        <a id={toKebabCase(title)} className="pt-32">
          {title}
        </a>
      </Title>
      {children}
    </section>
  );
}

export default Section;
