import React from "react";
import Title from "./title";

function CultivarDetails({ data, title, onClose }) {
  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center p-8">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
      <div className="modal-container bg-white md:w-8/12 rounded shadow-lg z-50 overflow-hidden h-full">
        <div className="modal-content py-4 text-left px-6 flex flex-col h-full">
          <div className="flex justify-between items-center">
            <Title variant="h4">{title}</Title>
            <button
              className="modal-close cursor-pointer z-50"
              onClick={onClose}
            >
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </button>
          </div>
          <div className="overflow-y-auto">
            {data.secoes && data.secoes.map(secao => (
              <div className="content mt-4 mb-8" key={secao.nome}>
                <Title variant="h5">{secao.nome}</Title>
                <table className="w-full border-collapse">
                  <tbody>
                    {secao.entradas.map(entrada => (
                      <tr key={entrada.chave}>
                        <td className="w-1/2 align-top py-1 pr-1 border-b border-solid border-gray-500 font-medium"
                          dangerouslySetInnerHTML={{ __html: entrada.chave }}
                        />
                        <td className="w-1/2 align-top py-1 pl-1 border-b border-solid border-gray-300"
                          dangerouslySetInnerHTML={{ __html: entrada.valor }}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CultivarDetails;
