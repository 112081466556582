import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Section from "./section";
import Title from "./title";
import CultivarList from "./cultivar-list";
import CultivarProducers from "./cultivar-producers";

const CheckItem = ({ children }) => (
  <div className="flex pb-4" style={{ width: "48%" }}>
    <div className="pt-1 pr-4">
      <FontAwesomeIcon icon={faCheckCircle} size="lg" />
    </div>
    <div>{children}</div>
  </div>
);

function Cultivars() {
  return (
    <Section title="Cultivares">
      <div>
        <p className="pb-4">
          Se você está indeciso sobre qual cultivar escolher para atender às
          suas necessidades, estamos aqui para ajudar.
        </p>
        <p className="pb-4">
          O uso de aveias pretas, brancas, amarelas ou cevada pode representar
          uma excelente alternativa para a cobertura do solo dentro do sistema
          de plantio direto. Essas culturas desempenham um papel crucial na
          reciclagem de nutrientes do solo, promovem o equilíbrio
          microbiológico, ajudam na descompactação e ainda facilitam o controle
          de ervas daninhas para as culturas de verão.
        </p>
        <p>
          Aqui estão os principais pontos a serem observados para garantir a
          escolha correta:
        </p>
      </div>
      <div>
        <Title variant="h3">Aveia Preta</Title>
        <div className="flex flex-wrap justify-between">
          {[
            "Melhoradora das condições físicas, químicas e sanitárias dos solos.",
            "Adapta-se a diversas condições ambientais.",
            "Possui efeito supressor/alelopático.",
            "Reduz doenças de raiz na rotação de cereais, especialmente trigo.",
            "Contribui para a conservação do solo em geral.",
            "Produz forragem a baixo custo.",
          ].map((text, i) => (
            <CheckItem key={i}>{text}</CheckItem>
          ))}
        </div>
      </div>
      <div>
        <Title variant="h3">Aveia Branca ou Amarela</Title>
        <div className="flex flex-wrap justify-between">
          {[
            "Fornecem forragem com valores necessários de energia e proteína para a produção animal.",
            "Alternativa a outros cultivos de inverno em sistemas ILP ou ILPF.",
            "Auxiliam no manejo de doenças e invasoras em sistemas integrados de plantio direto.",
            "Contribuem para a redução de nematoides.",
            "Contribuem para a conservação do solo em geral.",
          ].map((text, i) => (
            <CheckItem key={i}>{text}</CheckItem>
          ))}
        </div>
      </div>
      <div>
        <Title variant="h3">Cevada</Title>
        <div className="flex flex-wrap justify-between">
          {[
            "Oferece uma alternativa mais rápida para cobertura do solo e fornecimento de forragem.",
            "É adequada para a produção de silagem de pré-secado e planta inteira.",
            "Pode ser usada como grão alternativo ao milho para ração animal e etanol.",
            "Serve como marcadora de áreas de acidez/alumínio e compactação do solo (mapeamento da fertilidade).",
            "Pode ser uma alternativa à aveia branca na alimentação humana.",
          ].map((text, i) => (
            <CheckItem key={i}>{text}</CheckItem>
          ))}
        </div>
      </div>
      <div>
        <p>
          Ao considerar esses pontos específicos de cada cultivar, você poderá
          fazer uma escolha informada e selecionar a cultivar mais adequada às
          suas necessidades e condições locais.
        </p>
      </div>
      <div>
        <CultivarList />
      </div>
      <div>
        <CultivarProducers />
      </div>
    </Section>
  );
}

export default Cultivars;
