import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { OutboundLink } from "gatsby-plugin-google-gtag"

function Footer() {
  return (
    <footer className="flex flex-col flex-wrap items-center justify-between w-full p-4 md:p-8 text-gray-600 bg-verde">
      <div className="flex justify-center py-2">
        <div className="px-1">
          <OutboundLink
            href="https://www.facebook.com/Agroalpha-156618401408344/"
            rel="noopener noreferrer"
            title="Siga-me no Facebook"
            target="_blank"
            className="text-white opacity-70 hover:opacity-50"
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </OutboundLink>
        </div>
        <div className="px-1">
          <OutboundLink
            href="http://www.linkedin.com/company/agroalpha"
            rel="noopener noreferrer"
            title="Siga-me no LinkedIn"
            target="_blank"
            className="text-white opacity-70 hover:opacity-50"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </OutboundLink>
        </div>

        <div className="px-1">
          <OutboundLink
            href="https://www.instagram.com/agroalphagenetica"
            rel="noopener noreferrer"
            title="Siga-me no Instagram"
            target="_blank"
            className="text-white opacity-70 hover:opacity-50"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </OutboundLink>
        </div>
      </div>
      <div className="text-center text-white opacity-70">
        &copy; 2024{" "}
        <a href="/" title="Agroalpha" rel="bookmark" className="text-white">
          Agroalpha
        </a>
      </div>
    </footer>
  );
}

export default Footer;
